import { default as WebModule__Content } from 'models/modulor/web_module/content'
import ScrollIntoView from 'lib/modulor/scroll_into_view'
import _debounce from 'lodash/debounce'

export class Nav extends WebModule__Content
  @defaults =
    debug: false
    name: 'GardenMapModule__Nav'

  @selector: ".garden_map_module__nav"

  @property "viewOption",
    get: -> @element.dataset.viewOption

  @property "editMode",
    get: -> @element.dataset.editMode == "true"

  on_init: ->
    unless @editMode
      @scroll_handler = _debounce(@on_scroll.bind(this), 10)
      $(window).on "scroll.#{@options.eventNamespace}", @scroll_handler

    @$element.on "click.#{@options.name}", "a", (e) =>
      return unless dom_id = e.currentTarget.getAttribute("href")
      return unless $page_section = $(dom_id)
      e.preventDefault()
      ScrollIntoView.call $page_section, always: true

  on_destroy: ->
    $(window).off('scroll', @scroll_handler) if @scroll_handler

  on_resize: ->
    @initial_top = @element.getBoundingClientRect().top
    @on_scroll()

  on_scroll: ->
    y = -document.documentElement.scrollTop
    padding_top = parseInt(window.getComputedStyle(@element.parentElement).paddingTop)
    y = Math.max(y, -@element.offsetTop + padding_top)
    @element.style.transform = "translateY(#{y}px)"

Nav.register()
