import "src/application.css"

import 'modulor'

import 'channels'
import 'components'
import 'models'
import 'indexes'
import 'initializers'
import 'plugins'
import 'shared'
import 'view_objects'

import { } from 'jquery-ujs'

import Turbolinks from 'turbolinks'
Turbolinks.start()

document.addEventListener("turbolinks:load", function () {
  Turbolinks.clearCache();
});

import MutationObserver from 'lib/modulor/mutation_observer'
MutationObserver.start()

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.(js|coffee)$/)
application.load(definitionsFromContext(context))
