import { default as Plugin } from 'models/modulor/web_module/content'


export class Zoom extends Plugin
  @defaults =
    debug: false
    name: 'GardenMapModule__Zoom'

  @selector: ".garden_map_module__zoom"

  @property "ariaExpanded",
    get: -> @element.getAttribute('aria-expanded') == 'true'
    set: (value) -> @element.setAttribute('aria-expanded', value)

  on_init: ->
    @on_click_handler = @on_click.bind(this)
    @$element.on "click.#{@options.name}", ".garden_map_module__zoom__button", @on_click_handler

  on_destroy: ->
    @$element.off "click.#{@options.name}", ".garden_map_module__zoom__button", @on_click_handler

  on_click: (e) ->
    @ariaExpanded = !@ariaExpanded

Zoom.register()
