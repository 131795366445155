import { default as Plugin } from 'models/modulor/web_module/content'

export class DetailToggle extends Plugin
  @defaults =
    debug: false
    name: 'TextModule__DetailToggle'

  @selector: ".text_module__detail_toggle"

  @property "ariaExpanded",
    get: -> @element.getAttribute('aria-expanded') == 'true'
    set: (value) -> @element.setAttribute('aria-expanded', value)

  on_init: ->
    @on_click_handler = @on_click.bind(this)
    @$element.on 'click', @on_click_handler

  on_destroy: ->
    @$element.off 'click', @on_click_handler

  on_click: (event) ->
    @ariaExpanded = !@ariaExpanded

DetailToggle.register()
